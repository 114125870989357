<template>
  <div>
    <pr-request :from="'time-sheet'" />
  </div>
</template>
<script>
import PrRequest from '../project/PR Request/index.vue'

export default {
  components: {
    PrRequest,
  },

}
</script>
